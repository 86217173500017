// by convention, composable function names start with "use"
export function useFormValidation() {
	// state encapsulated and managed by the composable
	const emailRegex = /^((?!\.)[\w\-_.+]*[^.])(@[\w\-_]+)(\.\w+(\.\w+)?[^.\W])$/;
	const nameRegex = /^[a-zA-Z\-\'\.\,]+(?: [a-zA-Z\-\'\.\,]+)*$/;
	const passwordRegex =
		/^(?=.{8,128}$)(?=.*\d)(?=.*[!@#$%^&\*])(?=.*[A-Z])(?=.*[a-z])(?!.*[()=])(?!.*(\-\-|\/\*|\*\/|;|\+|\|\||=|>|<|<=|>=|==|<>|!=)).*$/;

	const dateRegex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})/;
	const checkSqlCharacters = [
		`'`,
		`--`,
		`#`,
		`/*`,
		`*/`,
		`%`,
		`;`,
		`+`,
		`||`,
		`=`,
		`>`,
		`<`,
		`<=`,
		`>=`,
		`==`,
		`<>`,
		`!=`,
		`*`,
	];

	function validateEmail(email: string) {
		return email !== "" && emailRegex.test(email);
	}

	function validateName(name: string) {
		return nameRegex.test(name);
	}

	function validateDate(date: string) {
		return dateRegex.test(date);
	}

	function validatePassword(password: string) {
		return passwordRegex.test(password);
	}

	function doPasswordsMatch(password: string, confirmPassword: string) {
		return password === confirmPassword;
	}

	function sanitizePassword(password: string) {
		return checkSqlCharacters.some((notAllowedCharacters) =>
			password.includes(notAllowedCharacters),
		);
	}

	return {
		validateEmail,
		validateName,
		validateDate,
		validatePassword,
		doPasswordsMatch,
		sanitizePassword,
	};
}
